import { guessCarrier } from "@deliverr/commons-utils";
import { cleanTrackingCode, validateBolNum, ValidatedTrackingInfo, validateMilkRun } from "@deliverr/ui-facility";

export function getValidatedTrackingInfo(trackingCode: string): ValidatedTrackingInfo {
  const carrier = guessCarrier(trackingCode);

  return {
    // If the carrier can't be identified (OTHER), it will only be valid if we can validate it as a BOL N
    isValid: carrier !== "OTHER" || validateBolNum(trackingCode) || validateMilkRun(trackingCode),
    carrier,
    usableTrackingCode: cleanTrackingCode(trackingCode, carrier),
  };
}
